import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
// import {Link} from 'gatsby'
// import { Bar } from 'react-chartjs-2'

const TaxPageTemplate = ({ title, notice, content, contentComponent, rateSchedule }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  // let dataset = rateSchedule.map( entry => {return (entry.mo+entry.ds).toFixed(4)}).reverse()
  // let legend = rateSchedule.map( entry => {return entry.year}).reverse()
  // let chartdata = {
  //   labels: legend,
  //   datasets: [
  //     {
  //       label: "Tax Rate",
  //       backgroundColor: "rgba(0,130,175,0.5)",
  //       data: dataset
  //     }
  //   ]
  // }
  // let chartoptions = {
  //   responsive: "true"
  // }
  // let LineChart = Bar;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              {/* <LineChart data={chartdata}  options={chartoptions} style={{ width: '100%' }}  /> */}
              <table className="table is-striped">
              <thead>
                <tr>
                  <td>Year</td>
                  <td>Debt Service</td>
                  <td>Maintenance</td>
                  <td>Road</td>
                  <td>Total Tax Rate</td>
                  <td>Order Levying Taxes</td>
                </tr>
              </thead>
              <tbody>
              {rateSchedule.map( entry => (
                <tr>
                  <td>{entry.year}</td>
                  <td>{entry.ds}</td>
                  <td>{entry.mo}</td>
                  <td>{entry.road }</td>
                  <td>$&nbsp;{(entry.mo + entry.ds + entry.road).toFixed(4)}</td>
                  {/* <td>$&nbsp;---</td> */}
                  { entry.order === null ? (<td></td>) : (<td><a className="button is-primary is-small" target="_blank" rel="noreferrer noopener" href={entry.order.publicURL}>Order Levying Taxes – {entry.year}</a></td>)}
                </tr>
              ))}
              </tbody>
              </table>
              <Notice notice={notice} />
            </div>
          </div>
        
          <div className="column is-4">
            <div className='section'>
              <h4 className='title is-size-4'>Tax Assessor/Collector</h4>
              <p><strong>Wheeler & Associates, Inc.</strong><br />
              Attn: Caryn Adams<br />
              6935 Barney Road, Suite 110<br />
              Houston, TX 77092<br />
              (713) 462-8906<br />
              <a href="https://www.wheelerassoc.com/">Website</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

TaxPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default TaxPageTemplate
